import Swiper from "swiper/bundle";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// register gsap  plugins
gsap.registerPlugin(ScrollTrigger);


jQuery(function($) {
  if($('.listing-articles-slider__slider .swiper-wrapper div').length > 0){
    const listingArticlesSlide = new Swiper('.listing-articles-slider__slider',{
      slidesPerView: 'auto',
      freeMode: true,
      grabCursor: true,
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true,
        dragSize:300
      }
    });
  }
  if($('.blog-categories__slider .swiper-wrapper div').length > 0){
    const listingCategoriesSlide = new Swiper('.blog-categories__slider',{
      loop:true,
      slidesPerView: 5,
      freeMode: true,
      grabCursor: true,
      slidesOffsetBefore: -80,
      slidesOffsetAfter: -80,
      breakpoints: {
        320: {
          slidesPerView: 2,
        },
        480: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
        },
        1025: {
          slidesPerView: 5,
        }
      }
    });
  }
  if ($('.categories-hero__slider .swiper-wrapper div').length > 0) {
    const listingCategoriesCircleSlide = new Swiper('.categories-hero__slider', {
      loop: true,
      slidesPerView: 7,
      freeMode: true,
      grabCursor: true,
      navigation: {
        nextEl: '.next',
      },
      breakpoints: {
        300: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 4,
        },
        1025: {
          slidesPerView: 7,
        }
      }
    });
  }
  if($('.blog__article .blog-articles__slider .swiper-wrapper div').length > 0){
    var swiperArtSingle= Swiper;
    var initArtSingle = false;
    function swiperModeArtSingle() {
      let mobile = window.matchMedia('(min-width: 0px) and (max-width: 850px)');
      let desktop = window.matchMedia('(min-width: 1025px)');
      if(mobile.matches) {
          if (!initArtSingle) {
            initArtSingle = true;
            swiperArtSingle = new Swiper('.blog__article .blog-articles__slider', {
                slidesPerView: 'auto',
                freeMode: true,
                grabCursor: true,
                scrollbar: {
                  el: '.swiper-scrollbar',
                  draggable: true,
                  dragSize: 300
                }
            });
         }
      }
      // Disable (for desktop)
      else if(desktop.matches) {
          swiperArtSingle.destroy();
          initArtSingle = false;
      }
    }
    window.addEventListener('load', function() {
        swiperModeArtSingle();
    });
    window.addEventListener('resize', function() {
        swiperModeArtSingle();
    });
  }

  if($('.news-ticker-container .news-one ul li').length > 0){
    var topics1 = document.querySelector('.ticker-1');
    gsap.to('.ticker-1', {x:-topics1.offsetWidth,repeat:-1,ease:'linear', duration: 40, force3D: true, rotation: 0.01});
  }
  if($('.news-ticker-container .news-two ul li').length > 0){
    var topics2 = document.querySelector('.ticker-2');
    gsap.to('.ticker-2', {x:-topics2.offsetWidth,repeat:-1,ease:'linear', duration: 15, force3D: true, rotation: 0.01});
  }
});
